<template>
  <form id="payment-form" @submit.prevent="addCard">
    <div class="form-row mx-0">
      <label for="card-element">
        <h6 class="text-muted">
          Please enter your credit or debit card:
        </h6>
      </label>
      <div id="card-element" ref="card_element">
        <!-- A Stripe Element will be inserted here. -->
      </div>

      <!-- Used to display Element errors. -->
      <div id="card-errors" role="alert">{{ cardErrors }}</div>
    </div>

    <button
      id="btnPaymentAddCard"
      class="btn btn-outline-success"
      data-loading-text="<i class='fa fa-circle-notch fa-spin'></i> Processing"
      v-if="hideButton !== true"
    >
      <i class="fas fa-plus"></i> Add Card
    </button>
  </form>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";

export default {
  name: "StripeCardElement",
  mixins: [FirebaseMixin, UtilsMixin],
  components: {},
  props: {
    hideButton: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      stripe: null,
      elements: null,
      card: undefined,
      cardErrors: null
    };
  },
  watch: {
    stripePubKey: {
      immediate: true,
      handler(key) {
        console.log(key);
        if (key) {
          // eslint-disable-next-line no-undef
          this.stripe = Stripe(this.stripeKey);
        }
      }
    },
    stripe: {
      immediate: true,
      handler(stripe) {
        if (stripe !== null) {
          this.$nextTick().then(() => {
            this.elements = stripe.elements();
            // Custom styling can be passed to options when creating an Element.
            var style = {
              base: {
                // Add your base input styles here. For example:
                fontSize: "16px",
                color: "#32325d"
              }
            };

            // Create an instance of the card Element.
            this.card = this.elements.create("card", { style: style });

            // Add an instance of the card Element into the `card-element` <div>.
            console.log("Stripe Card");
            console.log(this.$refs.card_element);
            this.card.mount(this.$refs.card_element);

            this.card.addEventListener("change", event => {
              if (event.error) {
                this.cardErrors = event.error.message;
                this.$emit("invalid-card", event.error.message);
              } else {
                this.cardErrors = "";
                this.$emit("valid-card");
              }
            });
          });
        }
      }
    }
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile"]),
    ...mapGetters(["stripeKey"]),
    userUid() {
      return this.userProfile.uid;
    },
    stripePubKey() {
      return this.stripeKey;
    }
  },
  methods: {
    addCard() {
      this.stripe.createToken(this.card).then(result => {
        if (result.error) {
          // Inform the customer that there was an error.
          this.cardErrors = result.error.message;
        } else {
          // Send the token to your server.
          console.log(result);
          var card = {
            token: result.token.id,
            expiration_year: result.token.card.exp_year,
            expiration_month: result.token.card.exp_month,
            last_four: result.token.card.last4,
            brand: result.token.card.brand
          };
          this.updatePaymentMethod(card);
        }
      });
    },
    updatePaymentMethod(card) {
      this.updateObject(
        "users/" + this.userUid + "/payment_method",
        null,
        card
      ).catch(error => {
        console.log(error);
        window.toastr.error(
          "An error occurred updating the card. Please try again later."
        );
      });
    }
  },
  created() {}
};
</script>

<style>
.StripeElement {
  /*background-color: white;
    height: 40px;
    padding: 10px 12px;
    border-radius: 4px;
    border: 1px solid transparent;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;*/
  height: 34px;
  width: 100%;
  padding: 6px 12px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  margin-bottom: 20px;
}

.StripeElement--focus {
  /*box-shadow: 0 1px 3px 0 #cfd7df;*/
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
</style>
