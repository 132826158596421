<template>
  <div>
    <h1 class="h3 mb-3">Settings</h1>

    <div class="row">
      <div class="col-md-3 col-xl-2">
        <div class="card">
          <div class="card-header">
            <h5 class="card-title mb-0">Profile Settings</h5>
          </div>

          <div class="list-group list-group-flush" role="tablist">
            <a
              :class="
                'list-group-item list-group-item-action' +
                (tab === 0 ? ' active' : '')
              "
              @click="tab = 0"
            >
              Account
            </a>
            <a
              :class="
                'list-group-item list-group-item-action' +
                (tab === 1 ? ' active' : '')
              "
              @click="tab = 1"
            >
              Password
            </a>
            <a
              :class="
                'list-group-item list-group-item-action' +
                (tab === 2 ? ' active' : '')
              "
              @click="tab = 2"
            >
              Billing
            </a>
            <!-- <a
              :class="
                'list-group-item list-group-item-action' +
                (tab === 3 ? ' active' : '')
              "
              @click="tab = 3"
            >
              Notifications
            </a> -->
            <a
              :class="
                'list-group-item list-group-item-action' +
                (tab === 4 ? ' active' : '')
              "
              @click="tab = 4"
            >
              Delete Account
            </a>
          </div>
        </div>
      </div>

      <div class="col-md-9 col-xl-10">
        <b-tabs nav-class="d-none" v-model="tab">
          <b-tab title="Profile">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title mb-0">Profile</h5>
              </div>
              <div class="card-body">
                <form @submit.prevent="saveProfile">
                  <div class="row">
                    <div class="col-md-8">
                      <div class="form-row">
                        <div class="form-group col-md-6">
                          <label for="inputFirstName">First name</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="e.g. John"
                            v-model="profile.firstName"
                            required
                          />
                        </div>
                        <div class="form-group col-md-6">
                          <label for="inputLastName">Last name</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="e.g. Smith"
                            v-model="profile.lastName"
                            required
                          />
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="inputEmail4">Email</label>
                        <input
                          type="email"
                          class="form-control"
                          placeholder="e.g. john.smith@gmail.com"
                          v-model="user.email"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="text-center">
                        <!-- <img
                          alt="Chris Wood"
                          src="@/assets/img/avatars/avatar.jpg"
                          class="rounded-circle img-responsive mt-2"
                          width="128"
                          height="128"
                        /> -->
                        <div class="d-inline-block mb-3">
                          <avatar
                            :username="userDisplayName"
                            :src="userAvatar"
                            :size="128"
                            class="avatar mt-2"
                          ></avatar>
                        </div>
                        <div class="mt-2">
                          <!-- <span class="btn btn-primary"
                            ><i class="fas fa-upload"></i> Upload</span
                          > -->
                          <label
                            class="btn btn-primary btn-file mr-1"
                            for="fileUploadAvatar"
                          >
                            <b-spinner small v-if="uploading"></b-spinner>
                            <span class="sr-only" v-if="uploading"
                              >Loading...</span
                            >
                            <i class="fas fa-upload mr-1" v-if="!uploading"></i>
                            <span v-if="!uploading">Upload</span>
                            <input
                              id="fileUploadAvatar"
                              type="file"
                              @change="uploadImage"
                            />
                          </label>
                          <b-button
                            variant="danger"
                            class="ml-1"
                            @click="deleteAvatar"
                            v-if="profile.avatar"
                          >
                            <i class="far fa-trash-alt"></i> Remove
                          </b-button>
                        </div>
                        <small
                          >For best results, use an image at least 128px by
                          128px in .jpg format</small
                        >
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="inputAddress">Address</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="e.g. 1234 Main St"
                      v-model="profile.address1"
                    />
                  </div>
                  <div class="form-group">
                    <label for="inputAddress2">Address 2</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="e.g. Apt. B"
                      v-model="profile.address2"
                    />
                  </div>
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label for="inputCity">City</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="e.g. New York"
                        v-model="profile.city"
                      />
                    </div>
                    <div class="form-group col-md-4">
                      <label for="inputState">State</label>
                      <b-form-select
                        :options="states"
                        v-model="profile.state"
                        placeholder="e.g. New York"
                      />
                    </div>
                    <div class="form-group col-md-2">
                      <label for="inputZip">Zip</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="e.g. 10001"
                        v-model="profile.zip"
                      />
                    </div>
                  </div>
                  <button type="submit" class="btn btn-primary">
                    Save changes
                  </button>
                </form>
              </div>
            </div>
          </b-tab>
          <b-tab title="Password">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Password</h5>

                <form @submit.prevent="changePassword">
                  <div class="form-group">
                    <label for="inputPasswordCurrent">Current password</label>
                    <input
                      type="password"
                      class="form-control"
                      id="inputPasswordCurrent"
                      placeholder="e.g. hunter2"
                      v-model="password.existing"
                    />
                    <small><a href="#">Forgot your password?</a></small>
                  </div>
                  <div class="form-group">
                    <label for="inputPasswordNew">New password</label>
                    <input
                      type="password"
                      class="form-control"
                      id="inputPasswordNew"
                      placeholder="e.g. hunter2"
                      v-model="password.new"
                    />
                  </div>
                  <div class="form-group">
                    <label for="inputPasswordNew2">Verify password</label>
                    <input
                      type="password"
                      class="form-control"
                      id="inputPasswordNew2"
                      placeholder="e.g. hunter2"
                      v-model="password.confirm"
                    />
                  </div>
                  <button type="submit" class="btn btn-primary">
                    Save changes
                  </button>
                </form>
              </div>
            </div>
          </b-tab>
          <b-tab title="Billing">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Plan</h5>

                <h3 class="text-center text-primary">
                  {{ subscription.plan.name }}
                </h3>
                <div class="list-group mt-3">
                  <div class="list-group-item list-group-item-action">
                    <div class="d-flex w-100 justify-content-between">
                      <h6 class="mb-1">Status</h6>
                      <span
                        :class="
                          subscription.status.toLowerCase() === 'active'
                            ? 'text-success'
                            : 'text-danger'
                        "
                      >
                        {{ subscription.status }}
                      </span>
                    </div>
                  </div>
                  <div class="list-group-item list-group-item-action">
                    <div class="d-flex w-100 justify-content-between">
                      <h6 class="mb-1">Subscribed</h6>
                      <span class="text-muted">{{
                        formatStripeDate(subscription.created)
                      }}</span>
                    </div>
                  </div>
                  <div class="list-group-item list-group-item-action">
                    <div class="d-flex w-100 justify-content-between">
                      <h6 class="mb-1">Next Billing Date</h6>
                      <span class="text-muted">{{
                        formatStripeDate(subscription.current_period_end)
                      }}</span>
                    </div>
                  </div>
                  <div class="list-group-item list-group-item-action">
                    <div class="d-flex w-100 justify-content-between">
                      <h6 class="mb-1">Payment Method</h6>
                      <div v-if="'last_four' in payment_method">
                        <div id="card-info">
                          <img
                            :src="brandLogos[payment_method.brand]"
                            alt="Card Brand Logo"
                            height="26"
                            v-if="payment_method.brand in brandLogos"
                          />
                          <span class="mx-2"
                            >*{{ payment_method.last_four }}</span
                          >
                          <small
                            ><i
                              >Expires {{ payment_method.expiration }}</i
                            ></small
                          >
                          <b-button
                            variant="outline-primary"
                            size="sm"
                            class="float-right ml-2"
                            v-b-modal.modal_update_card
                          >
                            <i class="fas fa-edit"></i>
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <b-button
                  variant="warning"
                  size="sm"
                  class="mt-3 mr-1"
                  :to="{ name: 'pricing' }"
                >
                  <i class="fas fa-edit"></i> Change Plan
                </b-button>
                <b-button
                  variant="danger"
                  size="sm"
                  class="mt-3 mr-1"
                  v-if="subscription.plan.name !== 'Free Plan'"
                >
                  <i class="fas fa-ban"></i> Cancel Plan
                </b-button>

                <b-modal
                  id="modal_update_card"
                  title="Update Payment Method"
                  header-bg-variant="primary"
                  header-text-variant="light"
                  ok-variant="primary"
                  :ok-disabled="newCardInvalid"
                  @ok="updateCard"
                >
                  <stripe-card-element
                    ref="cardElement"
                    :hide-button="true"
                    v-on:invalid-card="handleInvalidCard"
                    v-on:valid-card="handleValidCard"
                  ></stripe-card-element>
                </b-modal>
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Invoices</h5>

                <div id="invoices">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Date</th>
                        <th scope="col">Amount Paid</th>
                        <th scope="col">Amount Due</th>
                        <th scope="col">Amount Remaining</th>
                        <th scope="col">Paid</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(invoice, index) in invoices" :key="index">
                        <td>{{ invoice.number }}</td>
                        <td>{{ formatStripeDate(invoice.created) }}</td>
                        <td>{{ formatCurrency(invoice.amount_paid) }}</td>
                        <td>{{ formatCurrency(invoice.amount_due) }}</td>
                        <td>{{ formatCurrency(invoice.amount_remaining) }}</td>
                        <td>
                          <span
                            class="text-success font-weight-bolder"
                            v-if="invoice.paid"
                            >Yes</span
                          ><span
                            class="text-danger font-weight-bolder"
                            v-if="!invoice.paid"
                            >No</span
                          >
                        </td>
                        <td>
                          <a
                            :href="invoice.hosted_invoice_url"
                            target="_blank"
                            class="btn btn-outline-primary btn-sm float-right"
                            >View</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab title="Notifications">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Notifications</h5>

                <form @submit.prevent>
                  <button type="submit" class="btn btn-primary">
                    Save changes
                  </button>
                </form>
              </div>
            </div>
          </b-tab>
          <b-tab title="Delete Account">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Delete Account</h5>

                <p class="mt-3">
                  Click the button below if you wish to delete your account. If
                  you are on a paid plan, your subscription will be cancelled
                  effective immediately. Please note that account deletion is
                  permanent. Any projects that are owned by you will be gone and
                  cannot be recovered. This does not, however, affect any
                  projects that you have been invited to.
                </p>

                <button class="btn btn-danger" v-b-modal.delete_modal>
                  Delete account
                </button>

                <b-modal
                  id="delete_modal"
                  title="Confirm Deletion"
                  header-bg-variant="danger"
                  ok-variant="danger"
                  ok-title="Delete"
                  @ok="deleteAccount"
                >
                  <b-alert :show="alert.show" variant="danger"
                    ><div class="alert-message">
                      <i class="fas fa-exclamation-circle"></i>
                      {{ alert.message }}
                    </div></b-alert
                  >
                  <p class="my-1">
                    Please re-enter your password to authorize deleting your
                    account. This is permanent and cannot be undone.
                  </p>
                  <b-form-input
                    v-model="user.password"
                    placeholder="e.g. password"
                    type="password"
                  ></b-form-input>
                </b-modal>
              </div>
            </div>
          </b-tab>
        </b-tabs>

        <b-modal
          id="modal_reauth"
          title="Password Needed"
          header-bg-variant="warning"
          ok-variant="warning"
          @ok="tab === 4 ? deleteAccount() : updateEmail()"
        >
          <b-alert :show="alert.show" variant="danger"
            ><div class="alert-message">
              <i class="fas fa-exclamation-circle"></i> {{ alert.message }}
            </div></b-alert
          >
          <p class="my-1">
            Please re-enter your password to perform this operation:
          </p>
          <b-form-input
            v-model="user.password"
            placeholder="e.g. password"
            type="password"
          ></b-form-input>
        </b-modal>

        <b-modal
          id="modal_change_password"
          title="Change Password"
          header-bg-variant="primary"
          header-text-variant="light"
          ok-variant="primary"
          @ok="changePassword"
          @hide="validatePasswordChange"
        >
          <b-alert :show="alert.show" variant="danger"
            ><div class="alert-message">
              <i class="fas fa-exclamation-circle"></i> {{ alert.message }}
            </div></b-alert
          >
          <b-form-group
            label="Existing Password"
            label-for="input-existing-password"
            :invalid-feedback="password.existingFeedback"
            :state="password.existingState"
          >
            <b-form-input
              id="input-existing-password"
              v-model="password.existing"
              :state="password.existingState"
              trim
              type="password"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="New Password"
            label-for="input-new-password"
            :invalid-feedback="password.newFeedback"
            :state="password.newState"
          >
            <b-form-input
              id="input-new-password"
              v-model="password.new"
              :state="password.newState"
              trim
              type="password"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Confirm Password"
            label-for="input-confirm-password"
            :invalid-feedback="password.confirmFeedback"
            :state="password.confirmState"
          >
            <b-form-input
              id="input-confirm-password"
              v-model="password.confirm"
              :state="password.confirmState"
              trim
              type="password"
            ></b-form-input>
          </b-form-group>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";
import firebase from "firebase";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import StripeCardElement from "@/components/StripeCardElement";
import { v4 as uuidv4 } from "uuid";
import Avatar from "vue-avatar";
import { DateTime } from "luxon";

export default {
  name: "Profile",
  mixins: [FirebaseMixin, UtilsMixin],
  inject: ["notyf"],
  components: {
    Avatar,
    StripeCardElement,
  },
  data() {
    return {
      ref: "users",
      tab: 0,
      plan: {
        message: "",
      },
      user: {
        name: "",
        email: "",
        originalEmail: "",
        password: "",
      },
      profile: {
        firstName: "",
        lastName: "",
        avatar: "",
      },
      preferences: {},
      uploading: false,
      alert: {
        show: false,
        message: "Incorrect password",
      },
      password: {
        existing: "",
        existingState: null,
        existingFeedback: null,
        new: "",
        newState: null,
        newFeedback: null,
        confirm: "",
        confirmState: null,
        confirmFeedback: null,
      },
      states: [
        {
          text: "Alabama",
          value: "AL",
        },
        {
          text: "Alaska",
          value: "AK",
        },
        {
          text: "American Samoa",
          value: "AS",
        },
        {
          text: "Arizona",
          value: "AZ",
        },
        {
          text: "Arkansas",
          value: "AR",
        },
        {
          text: "California",
          value: "CA",
        },
        {
          text: "Colorado",
          value: "CO",
        },
        {
          text: "Connecticut",
          value: "CT",
        },
        {
          text: "Delaware",
          value: "DE",
        },
        {
          text: "District Of Columbia",
          value: "DC",
        },
        {
          text: "Federated States Of Micronesia",
          value: "FM",
        },
        {
          text: "Florida",
          value: "FL",
        },
        {
          text: "Georgia",
          value: "GA",
        },
        {
          text: "Guam",
          value: "GU",
        },
        {
          text: "Hawaii",
          value: "HI",
        },
        {
          text: "Idaho",
          value: "ID",
        },
        {
          text: "Illinois",
          value: "IL",
        },
        {
          text: "Indiana",
          value: "IN",
        },
        {
          text: "Iowa",
          value: "IA",
        },
        {
          text: "Kansas",
          value: "KS",
        },
        {
          text: "Kentucky",
          value: "KY",
        },
        {
          text: "Louisiana",
          value: "LA",
        },
        {
          text: "Maine",
          value: "ME",
        },
        {
          text: "Marshall Islands",
          value: "MH",
        },
        {
          text: "Maryland",
          value: "MD",
        },
        {
          text: "Massachusetts",
          value: "MA",
        },
        {
          text: "Michigan",
          value: "MI",
        },
        {
          text: "Minnesota",
          value: "MN",
        },
        {
          text: "Mississippi",
          value: "MS",
        },
        {
          text: "Missouri",
          value: "MO",
        },
        {
          text: "Montana",
          value: "MT",
        },
        {
          text: "Nebraska",
          value: "NE",
        },
        {
          text: "Nevada",
          value: "NV",
        },
        {
          text: "New Hampshire",
          value: "NH",
        },
        {
          text: "New Jersey",
          value: "NJ",
        },
        {
          text: "New Mexico",
          value: "NM",
        },
        {
          text: "New York",
          value: "NY",
        },
        {
          text: "North Carolina",
          value: "NC",
        },
        {
          text: "North Dakota",
          value: "ND",
        },
        {
          text: "Northern Mariana Islands",
          value: "MP",
        },
        {
          text: "Ohio",
          value: "OH",
        },
        {
          text: "Oklahoma",
          value: "OK",
        },
        {
          text: "Oregon",
          value: "OR",
        },
        {
          text: "Palau",
          value: "PW",
        },
        {
          text: "Pennsylvania",
          value: "PA",
        },
        {
          text: "Puerto Rico",
          value: "PR",
        },
        {
          text: "Rhode Island",
          value: "RI",
        },
        {
          text: "South Carolina",
          value: "SC",
        },
        {
          text: "South Dakota",
          value: "SD",
        },
        {
          text: "Tennessee",
          value: "TN",
        },
        {
          text: "Texas",
          value: "TX",
        },
        {
          text: "Utah",
          value: "UT",
        },
        {
          text: "Vermont",
          value: "VT",
        },
        {
          text: "Virgin Islands",
          value: "VI",
        },
        {
          text: "Virginia",
          value: "VA",
        },
        {
          text: "Washington",
          value: "WA",
        },
        {
          text: "West Virginia",
          value: "WV",
        },
        {
          text: "Wisconsin",
          value: "WI",
        },
        {
          text: "Wyoming",
          value: "WY",
        },
      ],
      subscription: { plan: { name: "Free Plan" }, status: "" },
      payment_method: {
        brand: "",
        last_four: "",
        expiration: "",
      },
      brandLogos: {
        amex: require("../../assets/img/payment-method-icons/American Express.png"),
        discover: require("../../assets/img/payment-method-icons/Discover.png"),
        mastercard: require("../../assets/img/payment-method-icons/MasterCard.png"),
        visa: require("../../assets/img/payment-method-icons/Visa.png"),
      },
      newCardInvalid: false,
      invoices: [],
    };
  },
  watch: {
    userUid: {
      immediate: true,
      handler(uid) {
        if (uid) {
          this.bindObject(this.ref + "/" + uid + "/profile", null, "profile");
          this.bindObject(
            this.ref + "/" + uid + "/payment_method",
            null,
            "payment_method"
          );
          this.bindObject(
            this.ref + "/" + uid + "/subscription",
            null,
            "subscription"
          );
          this.bindObject("invoices/" + uid, null, "invoices");
        }
      },
    },
    tab: {
      immediate: false,
      handler(tab) {
        switch (tab) {
          case 1:
            history.replaceState({}, null, "/account/password");
            break;
          case 2:
            history.replaceState({}, null, "/account/billing");
            break;
          case 3:
            history.replaceState({}, null, "/account/notifications");
            break;
          case 4:
            history.replaceState({}, null, "/account/delete");
            break;
          default:
            history.replaceState({}, null, "/account/profile");
        }
      },
    },
    subscription: {
      immediate: true,
      handler(subscription) {
        if (!("plan" in subscription)) {
          subscription.plan = {
            name: "Free Plan",
          };
        }
        if (!("status" in subscription)) {
          subscription.status = "";
        }
      },
    },
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile"]),
    ...mapGetters(["userDisplayName", "userAvatar"]),
    userUid() {
      return this.userProfile.uid;
    },
  },
  methods: {
    ...mapMutations(["setUser", "setProfile", "removeAvatar"]),
    setTab(name = null) {
      name = String(name).toLowerCase();
      console.log("Tab", name);
      switch (name) {
        case "profile":
          this.tab = 0;
          break;
        case "password":
          this.tab = 1;
          break;
        case "billing":
          this.tab = 2;
          break;
        case "notifications":
          this.tab = 3;
          break;
        case "delete":
          this.tab = 4;
          break;
        default:
          this.tab = 0;
      }
    },
    uploadImage() {
      const reader = new FileReader();
      const files = event.target.files;
      console.log(files);

      // Start button loading spinner
      this.uploading = true;

      if (files.length === 0) {
        // Stop button loading spinner
        this.uploading = false;
      } else {
        const file = files[0];
        const extension = file.name.split(".").pop();
        const filename = this.userUid + "/" + uuidv4() + "." + extension;
        console.log("File: " + filename);

        // Get storage reference
        const ref = firebase
          .storage()
          .ref()
          .child("avatars/" + filename);

        reader.addEventListener(
          "load",
          () => {
            ref.put(file).then(() => {
              this.profile.avatar_file = filename;
              this.getImageUrl("avatars/" + this.profile.avatar_file).then(
                (url) => {
                  this.profile.avatar = url;
                  this.saveProfile().then(() => {
                    // Stop button loading spinner
                    this.uploading = false;
                  });
                }
              );
            });
          },
          false
        );

        if (file) {
          reader.readAsBinaryString(file);
        }
      }
    },
    deleteAvatar() {
      if (this.profile.avatar) {
        const ref = firebase
          .storage()
          .ref()
          .child("avatars/" + this.profile.avatar_file);
        ref
          .delete()
          .then(() => {
            this.profile.avatar = null;
            this.profile.avatar_file = null;
            this.saveProfile();
          })
          .catch((error) => {
            if (error.code === "storage/object-not-found") {
              this.profile.avatar = null;
              this.profile.avatar_file = null;
              this.saveProfile();
            } else {
              console.log(error);
              this.notyf.error(
                "An error occurred while deleting the image. Please try again later."
              );
            }
          });
      }
    },
    saveProfile(hideAlerts = false) {
      const profile = { ...this.profile };

      return this.updateObject(
        this.ref + "/" + this.userUid + "/profile",
        null,
        profile
      )
        .then(() => {
          console.log("Profile saved successfully");
          this.setProfile(profile);
          if (!("avatar" in profile)) {
            this.removeAvatar();
          }
          if (hideAlerts !== false) {
            this.notyf.success("Profile saved successfully");
          }
        })
        .catch((error) => {
          console.log(error);
          if (hideAlerts !== false) {
            this.notyf.error(
              "An error occurred while saving the changes. Please try again later."
            );
          }
        });
    },
    save() {
      const user = firebase.auth().currentUser;

      user
        .updateProfile({
          displayName: this.user.name,
        })
        .then(() => {
          let updatedUser = { ...this.user };
          delete updatedUser.profile;
          this.setUser(updatedUser);
          // Check if email has changed
          if (this.user.email !== this.user.originalEmail) {
            user
              .updateEmail(this.user.email)
              .then(() => {
                this.notyf.success("Profile updated successfully");
              })
              .catch((error) => {
                console.log(error);
                if (error.code === "auth/requires-recent-login") {
                  this.alert.show = false;
                  this.$bvModal.show("modal_reauth");
                } else {
                  this.notyf.error(
                    "An error occurred updating your profile. Please try again later."
                  );
                }
              });
          } else {
            this.notyf.success("Profile updated successfully");
          }
        })
        .catch((error) => {
          console.log(error);
          this.notyf.error(
            "An error occurred updating your profile. Please try again later."
          );
        });
    },
    updateEmail() {
      const user = firebase.auth().currentUser;
      const credential = firebase.auth.EmailAuthProvider.credential(
        this.user.originalEmail,
        this.user.password
      );

      user
        .reauthenticateWithCredential(credential)
        .then(() => {
          // User re-authenticated.
          user
            .updateEmail(this.user.email)
            .then(() => {
              this.user.password = null;
              this.notyf.success("Profile updated successfully");
            })
            .catch((error) => {
              console.log(error);
              this.notyf.error(
                "An error occurred updating your profile. Please try again later."
              );
            });
        })
        .catch((error) => {
          console.log(error);
          if (error.code === "auth/wrong-password") {
            this.alert.show = true;
            this.$bvModal.show("modal_reauth");
          } else {
            this.notyf.error(
              "An error occurred updating your profile. Please try again later."
            );
          }
        });
    },
    validatePasswordChange(event) {
      // Perform validations
      let passed = true;
      if (!this.password.existing) {
        this.password.existingState = false;
        this.password.existingFeedback = "Please enter your current password.";
        passed = false;
      } else {
        this.password.existingState = null;
        this.password.existingFeedback = null;
      }
      if (!this.password.new || this.password.new.length === 0) {
        this.password.newState = false;
        this.password.newFeedback = "Please enter your new password.";
        passed = false;
      } else {
        this.password.newState = null;
        this.password.newFeedback = null;
      }
      if (!this.password.confirm || this.password.confirm.length === 0) {
        this.password.confirmState = false;
        this.password.confirmFeedback = "Please confirm your new password.";
        passed = false;
      } else if (this.password.confirm !== this.password.new) {
        this.password.confirmState = false;
        this.password.confirmFeedback = "Passwords do not match.";
        passed = false;
      } else {
        this.password.confirmState = null;
        this.password.confirmFeedback = null;
      }
      if (!passed) {
        event.preventDefault();
      }
    },
    changePassword() {
      const user = firebase.auth().currentUser;
      const credential = firebase.auth.EmailAuthProvider.credential(
        this.user.originalEmail,
        this.password.existing
      );

      user
        .reauthenticateWithCredential(credential)
        .then(() => {
          user
            .updatePassword(this.password.new)
            .then(() => {
              this.password.existing = "";
              this.password.new = "";
              this.password.confirm = "";
              this.notyf.success("Password changed successfully.");
            })
            .catch((error) => {
              console.log(error);
              this.notyf.error(
                "An error occurred while changing your password. Please try again later."
              );
            });
        })
        .catch((error) => {
          if (error.code === "auth/wrong-password") {
            this.alert.show = true;
            this.$bvModal.show("modal_change_password");
          } else {
            this.notyf.error(
              "An error occurred while changing your password. Please try again later."
            );
          }
        });
    },
    updatePreference(pref, trueVal, falseVal) {
      this.$nextTick().then(() => {
        this.profile[pref] = this.preferences[pref] ? trueVal : falseVal;
        this.saveProfile();
      });
    },
    updateCard() {
      this.$refs.cardElement.addCard();
    },
    handleInvalidCard() {
      this.newCardInvalid = true;
    },
    handleValidCard() {
      this.newCardInvalid = false;
    },
    formatStripeDate(date) {
      if (date) {
        const dt = DateTime.fromMillis(date);
        return dt.toLocaleString();
      }
    },
    formatCurrency(amount, currency = "usd") {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: currency,
      }).format(amount / 100);
    },
    deleteAccount() {
      const user = firebase.auth().currentUser;
      const credential = firebase.auth.EmailAuthProvider.credential(
        this.user.originalEmail,
        this.user.password
      );

      user
        .reauthenticateWithCredential(credential)
        .then(() => {
          // User re-authenticated.
          user
            .delete()
            .then(() => {
              this.navigate("signup");
            })
            .catch((error) => {
              console.log(error);
              this.notyf.error(
                "An error occurred deleting your account. Please try again later."
              );
            });
        })
        .catch((error) => {
          console.log(error);
          if (error.code === "auth/wrong-password") {
            this.alert.show = true;
            this.$bvModal.show("delete_modal");
          } else {
            this.notyf.error(
              "An error occurred deleting your account. Please try again later."
            );
          }
        });
    },
  },
  created() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.user.name = user.displayName;
        this.user.email = user.email;
        this.user.originalEmail = user.email;
      }
    });
    this.setTab(this.$route.params.tab);
  },
};
</script>

<style lang="scss" scoped>
.btn-file {
  position: relative;
  overflow: hidden;
  top: 4px;
}

.btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: #fff;
  cursor: inherit;
  display: block;
}
</style>
